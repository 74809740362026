<template>
  
  <div >
       <div class="header">
             <ul class="left_menu">
                <div class="item"> 
                    <li class="active a">会员管理</li>
                     <li class=" a"><router-link to="/index/hytj">会员统计</router-link></li>

                </div>
                 <div  class="item">  
                <li class="a" ><router-link to="/index/xiaoxi" style="display:flex;    align-items: center">  <i class=" el-icon-message-solid" style="color:#0034FF;width:25px;height:25px;border-radius:100%;background: #f5f5f5;text-align:center;line-height:25px;margin-right:5px"></i> <p style="width:15px;height:15px;background:red;text-align:center;font-size:12px;line-height:15px;border-radius:100%;color:#fff;margin-left:-10px;margin-top:-3px" v-if='result !==0' >{{result}}</p> 消息通知</router-link> </li>
                     <li style="cursor:pointer;" @click="dialogFormVisible = true">总台管理<i class="el-icon-caret-bottom"></i>  </li>
                </div>    

                                        <el-dialog title="个人信息" style="text-align:center" :visible.sync="dialogFormVisible">
                        <el-form :model="info">
                            <el-form-item label="昵称" :label-width="formLabelWidth">
                            <el-input v-model="info.name" autocomplete="off">  <el-button slot="append" @click="a11()">修改</el-button></el-input>
                         
                            </el-form-item>
                            <el-form-item label="登录账号" :label-width="formLabelWidth">
                             <el-input v-model="info.phone" autocomplete="off"  :disabled="true"></el-input>
                            </el-form-item>
                             <el-form-item label="登录密码" :label-width="formLabelWidth">
                             <el-input v-model="password3" placeholder="输入旧密码"  autocomplete="off">  <el-button slot="append"  @click="a22()">修改</el-button></el-input>
                            </el-form-item>
                            <el-form-item label="新密码" v-show="password2" :label-width="formLabelWidth">
                             <el-input v-model="password" autocomplete="off">  <el-button slot="append"  @click="a33()">确认修改</el-button> </el-input>
                            </el-form-item>
                        </el-form>
                        <div slot="footer" class="dialog-footer">
                         <el-button type="primary" @click="tuichu" style="width:100%">退出登录</el-button>
                        </div>
                        </el-dialog>  
             </ul>
        </div>
             <div class="search" style="padding:20px 40px; display:flex;font-size:14px;justify-content: space-between;align-items: center;border-top:1px solid #ccc">
            <div style=" display:flex;">
              

               <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">姓名</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="input2">
                </el-input>
            </div>

              <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">邀请码</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="input1">
                </el-input>
            </div>

            <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">手机</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="input3">
                </el-input>
            </div>

           <!-- <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">业务区域</span>  
                <el-cascader  :props="props" :options="options"   @change="changeProvinceCity"   size="small">
                    <template slot-scope="{ node, data }">
                        <span>{{ data.label }}</span>
                         <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                    </template>
                </el-cascader>
            </div> -->
            </div>
            <div style="display:flex" class="l">
                <p style="width: 80px;
height: 28px;line-height:28px;cursor: pointer;background:red;color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;margin-right:20px;text-align:center" @click="ck1">重置</p> <p style="width: 80px;
height: 28px;line-height:28px;text-align:center;cursor: pointer;background:rgb(128, 181, 71);color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;" @click="ck2">查询</p>
            </div>
        </div>
            <div class="content"  style="background:#f5f5f5;padding:10px 40px">
                     <ul class="content_ul">
                     <li style="background:#9351CC;color:#fff;height:90px;border-radius:10px;margin-right: 15px;">
                         <p style="font-size:14px;margin:12px 0">累计会员</p>
                          <p style="font-size:30px;margin: 0">{{infos.count_1}}</p>
                     </li>
                     <li style="background:#00ADD6;color:#fff;height:90px;border-radius:10px;margin-right: 15px;">
                         <p style="font-size:14px;margin:12px 0">本月累计会员</p>
                        <p style="font-size:30px;margin: 0">{{infos.count_2}}</p>
                     </li>
                     <li style="background:#F5C900;color:#fff;height:90px;border-radius:10px;margin-right: 15px;">
                         <p style="font-size:14px;margin:12px 0">今日新增会员</p>
                          <p style="font-size:30px;margin: 0">{{infos.count_3}}</p>
                     </li>
                      <li style="background:#49ADA8;color:#fff;height:90px;border-radius:10px;margin-right: 15px;">
                         <p style="font-size:14px;margin:12px 0">代理商来源</p>
                        <p style="font-size:30px;margin: 0">{{infos.count_4}}</p>
                     </li>
                     <li style="background:#80B547;color:#fff;height:90px;border-radius:10px;margin-right: 15px;">
                     <p style="font-size:14px;margin:12px 0">琴行来源</p>
           <p style="font-size:30px;margin: 0">{{infos.count_5}}</p>
                     </li>
                     <li style="background:#0093E0;color:#fff;height:90px;border-radius:10px;margin-right: 15px;">
                         <p style="font-size:14px;margin:12px 0">代理商 | 业务员来源</p>
         <p style="font-size:30px;margin: 0">{{infos.count_6}}</p>
                     </li>
                       <li style="background:#0093E0;color:#fff;height:90px;border-radius:10px;margin-right: 15px;">
                         <p style="font-size:14px;margin:12px 0">平台来源</p>
         <p style="font-size:30px;margin: 0">{{infos.count_7}}</p>
                     </li>
                 </ul>
                    
                          <el-table
                    :border="true"
                    :data="tableData"
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%">
                    
                    <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="id"
                    label="用户ID"
                    width="100">
                    </el-table-column>
                    <el-table-column
                        prop="wechatname" 
                    label="用户姓名"
                        :show-overflow-tooltip="true" 
                    width="120">
                    </el-table-column>

                    <el-table-column
                    label="手机号"
                    prop="phone" 
                    
                    width="150">
                    
                    </el-table-column>
                    

                    
                    <el-table-column
                        prop="yqr" 
                    label="邀请人" 
                        :show-overflow-tooltip="true" 
                    width="150">
                
                    </el-table-column>

                        <el-table-column
                        prop="province" 
                    label="用户地区"
                        :show-overflow-tooltip="true" 
                        width="150"
                    >
                    </el-table-column>
                      <el-table-column
                        prop="create_time" 
                    label="注册时间"
                        :show-overflow-tooltip="true" 
                        width="150"
                    >
                    </el-table-column>
                         <el-table-column
                        prop="lastlogintime" 
                    label="最近时间"
                        :show-overflow-tooltip="true" 
                      
                    >
                    </el-table-column>
                       <el-table-column
                       
                    label="删除"
                        :show-overflow-tooltip="true" 
                      
                    >
                      <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="danger"
                           
                        @click="sc(scope.row)">删除会员</el-button>
                           
                        
                    </template>
                    </el-table-column>
          
                </el-table>

                  <div class="block">

      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.page_index"
      :page-sizes="pagination.page_sizes"
      :page-size="pagination.page_size"
      :layout="pagination.layout"
      :total='pagination.total'
      style="margin-top:20px">
    </el-pagination>
  </div>
            
                 
            </div>
  </div>
</template>

<script>
export default {
    methods:{
        sc(row){
           
  this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
              this.$axios({
                        method: "post",
                        url: "/s/backend/index/delete_customer",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{
                        "phone":row.phone},
                        }) .then(res=>{
                            if(res.data.code == 0){
                             location.reload()

                          this.$message({
            type: 'success',
            message: '删除成功!'
          });
                            }else{
                                  this.$message({
            type: 'error',
            message: res.data.msg
          }); 
                            }

                  
                          
                                
                        })
        .catch(function(){
           
        })  
         
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });







        },
      handleSizeChange(val) {
this.pagination.page_size=val
  this.$axios.get("/s/backend/customer_list", {
        params: {
       'accessToken':localStorage.getItem('token'),
       page :this.pagination.page_index,
       limit :val,
      }
        })

     .then(res=>{
    
         this.tableData= res.data.data
        this.pagination.total = res.data.count
        })
        .catch(function(){
           
        })

            
                 
      },
      handleCurrentChange(val) {
       this.pagination.page_index=val
     


      this.$axios.get("/s/backend/customer_list", {
        params: {
       'accessToken':localStorage.getItem('token'),
       page :val,
      limit :this.pagination.page_size,
      }
        })

     .then(res=>{
    
         this.tableData= res.data.data
        this.pagination.total = res.data.count
        })
        .catch(function(){
           
        })

        
      },
          changeProvinceCity(val) { 
               let arr = []
        
         
                for(var i =0;i<val.length;i++){
             arr = arr.concat(val[i][2])
                }
          this.arr =arr
        
              
},
          ck1(){
               this.input1=''
               this.input2=''
                 this.input3=''
               this.arr = ''
               this.$axios.get("/s/backend/customer_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :10,
}
        })

     .then(res=>{
    
         this.tableData = res.data.data
 
     
        })
        .catch(function(){
           
        })
           },
           ck2(){
            
    this.$axios.get("/s/backend/customer_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :10,
        jobnumber:this.input1,
       name:this.input2,
       phone:this.input3,
       AreaId:this.arr
       }
        })

     .then(res=>{
    
         this.tableData = res.data.data
        this.jobnumber
        })
        .catch(function(){
           
        })
            
           },
        a22(){
            this.password2=true
         
              
        },
        a33(){

              this.$confirm('此操作将修改密码, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         
               if(this.password ==''){
                this.$message({
                   message:'请输入新密码后点击修改',
                  type: 'success'
                     }); 
            }else{
                    this.$axios({
                            method: "post",
                            url: "/s/backend/api/changepass",
                            params: {
                            accessToken : localStorage.getItem('token')
                            },
                            data:{
                        "jobnumber":  localStorage.getItem('jobnumber'),
                        "newpassword": this.password,
                        "oldpassword": this.password3
                        },
                            }) .then(res=>{
                            
                            if(res.data.code == 0){
                                    this.dialogFormVisible = false
                                    this.$message({
                                message:'修改成功',
                                type: 'success'
                                    });
                            }else{
                                    this.dialogFormVisible = false
                                    this.$message({
                                        message:res.data.msg,
                                        type: 'success'
                                            });
                            }
                            })
            .catch(function(){
            
            })  
            }

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          });          
        });







            
        },
          tuichu(){
            this.dialogFormVisible = false;
            window.localStorage.removeItem('token')
                     this.$router.push({ name: 'login'})
        },
        a33(){
               if(this.password ==''){
                this.$message({
                   message:'请输入新密码后点击修改',
                  type: 'success'
                     }); 
            }else{
                    this.$axios({
                            method: "post",
                            url: "/s/backend/api/changepass",
                            params: {
                            accessToken : localStorage.getItem('token')
                            },
                            data:{
                        "jobnumber":  localStorage.getItem('jobnumber'),
                        "newpassword": this.password,
                        "oldpassword": this.password3
                        },
                            }) .then(res=>{
                            
                            if(res.data.code == 0){
                                    this.dialogFormVisible = false
                                    this.$message({
                    message:'修改成功',
                    type: 'success'
                        });
                            }else{
                                        this.$message({
                    message:res.data.msg,
                    type: 'success'
                        });
                            }
                            })
            .catch(function(){
            
            })  
            }
        },
    },
 data(){
        return{
            pagination:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
            
     
            result:'',
                props: { multiple: true },
input1:'',
input3:'',
input2:'',
options:[],
arr:[],
            infos:[],
                dialogFormVisible:false, form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: '',
          infos:[],
        },
        formLabelWidth: '120px',
        info:[],

            activeName: 'first',
    
                 tableData: [],
                    data1:[],
                    data2:[],
                 password:'',
                   password2:false,
                  password3:''
        }
        },
       
        mounted(){

              
this.$axios.get("/s/backend/message/get_message_unread", {
　  　params: { 'accessToken':localStorage.getItem('token')}
})
  .then(res=>{
         this.result = res.data.result
          
        })
        .catch(function(){
           
        })   
           
this.$axios.get("/s/backend/arealist")

     .then(res=>{
    
        console.log(res.data)
        this.options = res.data.data
 
        })
        .catch(function(){
           
        })
          this.$axios.get("/s/backend/api/user_detail", {
        params: { 'accessToken':localStorage.getItem('token'),
        'jobnumber':localStorage.getItem('jobnumber')
        }
        })

     .then(res=>{
          this.info = res.data.data
           
        })
        .catch(function(){
           
        })
               this.$axios.get("/s/backend/c_banner", {
    　  　params: { 'accessToken':localStorage.getItem('token')
        
    }
    })

        .then(res=>{
                this.infos = res.data.result
     
        })
        .catch(function(){
           
        })
   this.$axios.get("/s/backend/customer_list", {
        params: {
       'accessToken':localStorage.getItem('token'),
       page :1,
       limit :7,
      }
        })

     .then(res=>{
    
         this.tableData= res.data.data
        this.pagination.total = res.data.count
        })
        .catch(function(){
           
        })

         
  

              
        }
}
</script>

<style scoped>

/deep/.el-dialog__wrapper{
    top:-50px
}

/deep/ .el-dialog{
        width: 25%;

    float: right;
  
}



 .content .content_ul{
            display: flex;
            justify-content: space-between;margin:30px 0;
        }
         .content .content_ul li{
             width: 12%;padding-left: 15px;
         }


.item li:hover{
    color: #0034FF;
}
.item li a:hover{
    color: #0034FF !important;
}
   /deep/.el-table th > .cell {
      text-align: center;

    }

    /deep/.el-table .cell {
      text-align: center;

    }

/deep/.el-tabs--card>.el-tabs__header{
    border-bottom: none !important;
}

/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item{
    border-bottom:1px solid #E4E7ED;}
/deep/ .el-tabs__item.is-active{
    border:1px solid #409EFF;
    border-left:1px solid #409EFF !important;
      border-bottom:1px solid #409EFF !important;
}
    .header{
            min-height: 57px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
    .header .left_menu{
        display: flex;   width: 100%;  padding:0 40px;
        justify-content: space-between;
    }
    .header .left_menu .item{
        display: flex;
        align-items: center;
    }
        .header .left_menu .item .a{
            margin-right: 60px;  
            cursor: pointer;
        }
           .header .left_menu .item li a{
               text-decoration: none;color: #000;font-size: 16px;
font-weight: 500;
           }

        .header .active{
            color: #0034FF;
            position:relative;
        }
             .header .active::before{
                      content: "";
   
         left: 0;
    position: absolute;
    bottom:-120%;
    height:3px;
    width:30px;
    background: #0034FF;
            }
</style>